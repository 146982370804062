import to from 'await-to-js';
import * as Types from './Types';
import {APIUtils} from '@trendads/common';

export * from './Types';
export const URLPATH = '/.crm/banner/campaigns';

export function getCampaigns(host: string, params: Types.RequestParams) {
    if (params.campaignCount === '0') return to<Types.Campaign[]>(Promise.resolve([]));
    return to<Types.Campaign[]>(
        fetch(`${host}${URLPATH}?${APIUtils.paramsToQuery(params)}`).then(async response => {
            if (!response.ok) {
                throw new Error(await response.text());
            }

            const campaigns: Types.Campaign[] = await response.json();

            return campaigns.map((campaign: Types.Campaign) => ({
                campaignId: campaign.campaignId,
                creativeId: campaign.creativeId ?? campaign.campaignId
            }));
        })
    );
}

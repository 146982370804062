import * as React from 'react';
import CONFIG from './Config';

export const LazyCreativeManagement = React.lazy(() =>
    import('@trendads/common/dist/creative-management/CreativeManagement')
);

const CreativeManagement: React.FC = () => {
    return (
        <React.Suspense fallback={null}>
            <LazyCreativeManagement config={CONFIG} />
        </React.Suspense>
    );
};

export default CreativeManagement;

import * as React from 'react';
import styles from './App.module.css';
import {Config, Hooks, Contexts, Components, Types} from '@trendads/common';
import CONFIG, {resolvePath} from './Config';

export const LazyConfigurator = React.lazy(() => import('@trendads/common/dist/components/Configurator'));
export const LazyOverview = React.lazy(() => import('@trendads/common/dist/components/Overview'));

const Templates = Config.buildTemplates(CONFIG, resolvePath);

const App: React.FC = () => {
    const {loading, templateParams, requestParams, configuratorParams} = Hooks.useInit(
        CONFIG,
        ({match, deeplinks, campaignId, requestParams, encodeDeepLink}) => {
            const url = new URL(CONFIG.linkTarget + `/${requestParams.language}`);
            url.searchParams.set('utm_medium', 'banner');

            let bannerSource;
            try {
                bannerSource =
                    window.location.hostname !== window.top?.location.hostname
                        ? document.referrer
                        : window.top.location.hostname;
            } catch (err) {
                bannerSource = document.referrer;
            }
            url.searchParams.set('utm_source', bannerSource);

            if (match) {
                url.searchParams.set('contestId', match.id);
            }

            if (campaignId) {
                url.searchParams.set('utm_content', campaignId);
            }

            if (requestParams.affiliateId) {
                url.searchParams.set('a', requestParams.affiliateId);
                url.searchParams.set('utm_campaign', requestParams.affiliateId);
            }

            if (deeplinks && deeplinks.length > 0) {
                url.searchParams.append('contestId', deeplinks[0].contestId);

                const mode = deeplinks.length === 1 ? 'Single' : 'Combi';
                const stake = requestParams.stake;

                const selectionRows = deeplinks.map(deeplink => ({
                    rowKey: {
                        contestId: deeplink.contestId,
                        bettingType: deeplink.bettingTypeKey,
                        outcome: deeplink.outcomeKey
                    },
                    stake: mode === 'Single' ? stake : undefined
                }));

                const systemRows = mode === 'Combi' ? [{rowKey: {system: deeplinks.length, stake}}] : undefined;
                url.searchParams.append('betslip', JSON.stringify({mode, selectionRows, systemRows}));
            }

            return encodeDeepLink(url.toString());
        }
    );

    if (loading) return null;

    if (requestParams?.template !== undefined && requestParams?.size !== undefined && templateParams) {
        if (requestParams?.overview) {
            return (
                <React.Suspense fallback={null}>
                    <LazyOverview config={CONFIG} requestParams={requestParams} />
                </React.Suspense>
            );
        } else {
            const Template =
                requestParams.template && requestParams.size
                    ? Templates[requestParams.template + requestParams.size]
                    : null;

            if (Template) {
                return (
                    <div className={`${styles.App} ${requestParams!.size === Types.Size.API ? styles.apiSize : ''}`}>
                        <Contexts.DebugMode.Provider value={requestParams.debug === 'true'}>
                            <Components.ErrorBoundary fallback={<>Template not available</>}>
                                <React.Suspense fallback={null}>
                                    <Template {...templateParams} />
                                </React.Suspense>
                            </Components.ErrorBoundary>
                        </Contexts.DebugMode.Provider>
                    </div>
                );
            } else {
                return <div>Invalid template</div>;
            }
        }
    }

    if (configuratorParams) {
        return (
            <React.Suspense fallback={null}>
                <LazyConfigurator {...configuratorParams} />
            </React.Suspense>
        );
    }

    return null;
};

export default App;

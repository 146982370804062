import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import CreativeManagement from './CreativeManagement';

if (window.location.pathname === '/creative-management' || window.location.search.includes('creative-management')) {
    ReactDOM.render(<CreativeManagement />, document.getElementById('root'));
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}

import {Config, Types, MatchAPI, RequestParams, Cookie} from '@trendads/common';
import * as CampaignAPI from './api/campaign';
import * as CreativeAPI from '@trendads/common/dist/creative-management/RemoteAPI';

export async function resolvePath(path: string) {
    return await import('./templates/' + path);
}

const API_HOST = (() => {
    // if (window.location.hostname === 'localhost') return 'https://neo.bet';
    if (window.location.hostname === 'localhost') return 'https://test.reactivebetting.com';
    switch (window.location.hostname) {
        case 'banner.neobet.de':
            return 'https://neobet.de';
        case 'banner-on.neobet.ca':
            return 'https://on.neobet.ca';
        case 'banner.neo.bet':
            return 'https://neo.bet';
        case 'banner-test.reactivebetting.de':
            return 'https://test.reactivebetting.de';
        case 'banner-on-test.reactivebetting.com':
            return 'https://on-test.reactivebetting.com';
        case 'banner-test.reactivebetting.com':
            return 'https://test.reactivebetting.com';
        default:
            return 'https://test.reactivebetting.com';
    }
})();

const licensesByHostname = {
    [Types.License.DE]: ['banner.neobet.de', 'banner-test.reactivebetting.de'],
    [Types.License['CA-ON']]: ['banner-on.neobet.ca', 'banner-on-test.reactivebetting.com'],
    [Types.License.MT]: ['banner.neo.bet', 'banner-test.reactivebetting.com']
};

const CONFIG: Config.Config = {
    customer: 'neobet',
    apiHost: API_HOST,
    i18nSheet: 'https://docs.google.com/spreadsheets/d/13Gbno01W4gidO_MOr7c2H4-bh_jFZzyyLeY55KtxZ1Q',
    i18nHost: 'https://trendads.reactivebetting.com/i18n/neobet',
    creativeApiHost: 'https://trendads-creative-api.reactivebetting.com',
    linkTarget: API_HOST,
    trackingEnabled: true,
    supportedTemplates: [Types.Template.MatchBanner],
    supportedSlideTypes: [Types.SlideType.Match, Types.SlideType.Campaign, Types.SlideType.Creative],
    supportedThemes: [Types.Theme.Bright, Types.Theme.Dark],
    supportedLanguages: [Types.Language.en, Types.Language.de, Types.Language.fi],
    trendsFallbackLanguages: {},
    supportedCountries: Object.keys(Types.Country) as Types.Country[],
    supportedCurrencies: [Types.Currency.EUR, Types.Currency.USD, Types.Currency.CAD],
    supportedOddsFormats: [Types.OddsFormat.Decimal, Types.OddsFormat.American, Types.OddsFormat.Fractional],
    supportedSports: undefined, // ALL
    supportUrlContextAnalysis: true,
    supportedLicenses: [Types.License.MT, Types.License.DE, Types.License['CA-ON']],
    defaultLicense: Types.License.MT,
    lazyLoading: true,
    defaults: {
        template: Types.Template.MatchBanner,
        theme: Types.Theme.Dark,
        language: Types.Language.en,
        oddsFormat: Types.OddsFormat.Decimal,
        currency: Types.Currency.EUR,
        bettingType: MatchAPI.BettingType.MatchWin,
        updateInterval: 60 * 1000,
        matchCount: 5,
        campaignCount: 0,
        stake: 10,
        sortBy: MatchAPI.SortBy.Popularity
    },
    getLicense: (config: Config.Config) => {
        let licenseByUrl: Types.License | undefined;
        if (window.location.hostname === 'localhost') {
            const searchParams = new URLSearchParams(window.location.search);
            licenseByUrl = (searchParams.get('license')?.toUpperCase() ?? undefined) as Types.License | undefined;
        } else {
            licenseByUrl = Object.entries(licensesByHostname).find(([, hostnames]) => {
                return hostnames.includes(window.location.hostname);
            })?.[0] as Types.License | undefined;
        }

        if (licenseByUrl && config.supportedLicenses.includes(licenseByUrl)) {
            return licenseByUrl;
        } else {
            return config.defaultLicense;
        }
    },
    getSupportedBettingTypesByTemplate: (template: Types.Template): MatchAPI.BettingType[] => {
        switch (template) {
            case Types.Template.MatchBanner:
                return [
                    MatchAPI.BettingType.MatchWin,
                    MatchAPI.BettingType.OddsBoost,
                    // MatchAPI.BettingType.OverUnder,
                    MatchAPI.BettingType.Spread,
                    MatchAPI.BettingType.Handicap
                ];
            default:
                return [];
        }
    },
    getSupportedSizesByTemplate: (template: Types.Template): Types.Size[] => {
        switch (template) {
            case Types.Template.MatchBanner:
                return [
                    Types.Size['120x240'],
                    Types.Size['120x600'],
                    Types.Size['160x600'],
                    Types.Size['200x200'],
                    Types.Size['300x600'],
                    Types.Size['300x250'],
                    Types.Size['320x50'],
                    Types.Size['320x75'],
                    Types.Size['320x150'],
                    Types.Size['320x320'],
                    Types.Size['320x480'],
                    Types.Size['350x120'],
                    Types.Size['360x80'],
                    Types.Size['360x120'],
                    Types.Size['384x50'],
                    Types.Size['392x50'],
                    Types.Size['360x50'],
                    Types.Size['400x600'],
                    Types.Size['411x50'],
                    Types.Size['450x125'],
                    Types.Size['468x60'],
                    Types.Size['600x600'],
                    Types.Size['600x1200'],
                    Types.Size['640x100'],
                    Types.Size['640x120'],
                    Types.Size['640x130'],
                    Types.Size['640x150'],
                    Types.Size['640x300'],
                    Types.Size['640x320'],
                    Types.Size['680x100'],
                    Types.Size['680x1200'],
                    Types.Size['700x110'],
                    Types.Size['728x90'],
                    Types.Size['768x245'],
                    Types.Size['800x250'],
                    Types.Size['900x150'],
                    Types.Size['970x90'],
                    Types.Size['970x250'],
                    Types.Size['1400x220'],
                    Types.Size['1596x367'],
                    Types.Size['1600x150'],
                    Types.Size['1920x1080'],
                    Types.Size.API
                ];
            default:
                return [];
        }
    },

    getMaxMatchCountPerSlide: (template: Types.Template, size: Types.Size): number => {
        switch (template) {
            default:
                switch (size) {
                    default:
                        return 1;
                }
        }
    },

    resolvePath,

    loadImage: async (path: string): Promise<string> => {
        return (await import(`./data/${path}`)).default;
    },

    loadCreatives: async (config: Config.Config): Promise<string[]> => {
        return CreativeAPI.getCreativeIds(config);
    },

    loadCampaigns: async (
        campaignCount: number,
        requestParams: RequestParams.RequestParams,
        config: Config.Config
    ): Promise<Types.Campaign[]> => {
        const [error, campaigns] = await CampaignAPI.getCampaigns(API_HOST, {
            campaignCount: String(campaignCount),
            country: requestParams.country?.toString(),
            affiliateId: requestParams.affiliateId,
            customerId: requestParams.customerId
        });

        if (error || !campaigns) {
            if (error) console.error(error);
            return [];
        }

        return campaigns.map(campaign => ({creativeId: campaign.creativeId, id: campaign.campaignId}));
    },

    getCustomerId: () => Cookie.getCookieValue('BannerToken'),

    loadCreative: async (
        id: string,
        requestParams: RequestParams.RequestParams,
        config: Config.Config
    ): Promise<string | null> => {
        const size = requestParams.size;
        if (!size) return null;

        return CreativeAPI.getCreative(config, id, requestParams.language, size);
    },

    fallbackCreativeId: 'livequotenboosts',

    getDefaultSlides: language => ({before: [], after: []}),

    creativeManagement: {
        authClientId: '1036749859651-7gi8f941h5djv3e435vk6rf9rfdski9b.apps.googleusercontent.com',
        awsRole: 'arn:aws:iam::040973466624:role/TrendAdsGoogle'
    }
};

export default CONFIG;
